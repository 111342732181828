@import url("https://fonts.googleapis.com/css2?family=DM+Mono:wght@300;400;500&display=swap");

body {
  padding: 0;
  margin: 0;
  background-color: #17141d;
  color: white;
  font-family: "DM Mono", monospace;
}

a {
  text-decoration: none;
}

.card-list {
  display: flex;
  padding: 4rem;
  padding-top: 6rem;
  overflow-x: scroll;
  align-items: center;
  justify-content: center;
}

.card-list::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.card-list::-webkit-scrollbar-thumb {
  background: #101021;
  border-radius: 10px;
}

.card {
  height: 350px;
  width: 400px;
  min-width: 250px;
  padding: 1.5rem;
  border-radius: 16px;
  background: #17141d;
  box-shadow: -1rem 0 3rem #000;
  display: flex;
  flex-direction: column;
  transition: 0.2s;
  margin: 0;
  scroll-snap-align: start;
  clear: both;
  position: relative;
}

.card:focus-within ~ .card,
.card:hover ~ .card {
  transform: translateX(130px);
}

.card:hover {
  transform: translateY(-1rem);
}

.card:not(:first-child) {
  margin-left: -130px;
}

.card h1 {
  padding-bottom: 5.5rem; 
  font-size: 50px;
}

.card-header {
  margin-bottom: auto;
}

.card-header p {
  font-size: 14px;
  margin: 0 0 1rem;
  color: #7a7a8c;
}

.card-header h2 {
  font-size: 20px;
  margin: 0.25rem 0 auto;
  text-decoration: none;
  color: inherit;
  border: 0;
  display: inline-block;
  cursor: pointer;
}

.card-header h2:hover {
  background: linear-gradient(90deg, #ff8a00, #e52e71);
  text-shadow: none;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}
